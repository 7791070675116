* {
  outline: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  color: #999;
  height: auto !important;
  min-height: 100% !important;
  background: #f9f9f9;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}
